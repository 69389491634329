@import url("https://fonts.googleapis.com/css?family=Montserrat:wght@200&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #343434;
  color: #fff;
}

.MuiTextField-root {
  color: #d4af37;
  text-emphasis-color: #d4af37;
  border-radius: 15px;
  font-style: oblique;
}
ul.MuiList-root {
  padding-top: 0;
  padding-bottom: 0;

  border-radius: 40px;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected {
  background-color: #756300;
  background: #756300;
  font-style: oblique;

  color: #343434;
}
.MuiListItem-root.Mui-selected:hover {
  background-color: #756300;
  font-style: oblique;

  color: #343434;
}

.MenuItem {
  color: "#d4af37";
}

.MenuItem.selected {
  background-color: #343434;
}

.dateContainer {
  display: inline-flex;
  flex: 1;
  width: 95%;
  margin-left: 1%;
  margin-right: 2%;
  overflow-x: auto;
  padding-bottom: 0px;
}

.buttonContainer {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flow: row;
  justify-content: space-around;

  line-height: 30px;
}
